*, *:before, *:after {
  box-sizing: border-box;
  cursor: none;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  color: white;
}

::selection {
  user-select: none;
}

footer, header, nav, section, main {
  display: block;
}

html {
  min-width: 350px;
  scroll-behavior: smooth;
}
html body {
  --primary-1: hsl(0deg, 0%, 95%);
  --primary-2: hsl(0deg, 0%, 85%);
  --secondary-1: rgb(148, 148, 148);
  --secondary-2: hsl(0deg, 1%, 29%);
  --card-1: hsl(0, 0%, 0%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: hsl(0deg, 0%, 0%);
  background-image: url("https://www.transparenttextures.com/patterns/binding-dark.png");
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
html body:has(.no-scroll) {
  overflow-y: hidden;
}
html body main {
  flex-grow: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  border-radius: 0;
}

a {
  text-decoration: none;
}

.cursor-big {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid hsl(0deg, 0%, 100%);
  transition: all cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.2s;
  mix-blend-mode: difference;
  pointer-events: none;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.cursor-big.expand {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.cursor-big.unset {
  transition: none;
}

.cursor-small {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: hsl(0deg, 0%, 100%);
  mix-blend-mode: difference;
  pointer-events: none;
  position: fixed;
  transform: translate(-50%, -50%);
  transition: width 1s, height 1s;
  z-index: 9999;
}
.cursor-small.expand {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

main {
  display: flex;
  flex-direction: column;
}
main section {
  padding-top: 3em;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 20em;
}
main section .intro-title {
  padding: 2em;
  max-width: 750px;
}
main section .title {
  padding-left: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5em;
  row-gap: 1em;
}
main section .title h1 {
  font-size: 4rem;
  color: white;
  text-align: center;
}
main section .title p {
  text-align: center;
  font-size: 1.2rem;
}
main section .slider {
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}
main section .button-container {
  display: flex;
  column-gap: 1.5em;
  z-index: 0;
}
main section#intro {
  column-gap: 20em;
  align-items: center;
  flex-direction: row;
}
main section#intro h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: white;
}
main section#intro h2 {
  color: var(--secondary-1);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
main section#intro p {
  color: var(--secondary-1);
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
@media (max-width: 1100px) {
  main section#intro {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

header {
  top: 0;
  padding: 1em;
  position: sticky;
  display: flex;
  justify-content: space-between;
  z-index: 50;
}
header .logo, header nav ul {
  display: flex;
  column-gap: 1em;
}
header .logo a, header nav ul a {
  font-size: 16px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 50%;
  border: 1px solid hsl(0deg, 0%, 100%);
  background-color: hsl(0deg, 0%, 100%);
}
.button.active {
  background-color: hsl(0deg, 0%, 0%);
  border: 1px solid hsl(0deg, 0%, 0%);
}

.card {
  width: 256px;
  height: 412px;
  position: relative;
  left: 0px;
  background-color: transparent;
  box-shadow: -1em 0 3rem rgb(17, 17, 17);
  transition: 0.5s ease;
  transform-style: preserve-3d;
}
.card:not(:first-child) {
  margin-left: -10em;
}
.card:hover {
  transform: translateY(-3em);
  transition: 0.8s ease;
}
.card:hover ~ .card {
  left: 11em;
  transition: 0.8s ease;
}
.card.flip {
  transform: rotateY(180deg) translateY(-3em);
}
.card .card-front, .card .card-back {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/black-lozenge.png");
  background-color: var(--card-1);
  border: solid 1px var(--secondary-2);
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  --webkit-backface-visibility: hidden;
  --moz-backface-visibility: hidden;
  --ms-backface-visibility: hidden;
  --o-backface-visibility: hidden;
  --backface-visibility: hidden;
}
.card .card-back {
  position: relative;
  transform: rotateY(180deg);
}
.card .card-back .link-container {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
}
.card .card-back .logo-container {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.08;
}
.card .card-back .logo-container img.top {
  position: absolute;
  top: 1em;
  left: 1em;
}
.card .card-back .logo-container img.bottom {
  position: absolute;
  bottom: 1em;
  right: 1em;
}
.card .card-back .logo-container img.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card .card-back .text-container {
  padding: 2em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}
.card .card-back .text-container p, .card .card-back .text-container a {
  text-align: center;
}

.popup {
  position: fixed;
  top: 0;
  width: 100%;
  height: 0;
  background-color: #000000;
  opacity: 0;
  transition: all 0.7s ease;
}
.popup.active {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  z-index: 100;
}
.popup .popup-exit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
}
.popup .popup-exit #btn-close-popup {
  background-color: transparent;
}
.popup .popup-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  row-gap: 0.5em;
}
.popup .popup-text a {
  font-size: 20px;
}
.popup .popup-text a:hover {
  color: #f2f2f2;
}
.popup .popup-text.active {
  opacity: 1;
}
.popup .popup-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
  width: 100%;
}
.popup .popup-animation svg {
  position: relative;
  fill: white;
  height: auto;
  transform-origin: center;
  animation: rotate 60s infinite linear;
  max-width: 54em;
  width: 100%;
  opacity: 0.25;
}
.popup .popup-animation svg text {
  opacity: 0;
  font-size: 9.5px;
}
.popup .popup-animation svg text.active {
  opacity: 1;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.timeline {
  display: flex;
  flex-direction: column;
}
.timeline ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 200px;
}
.timeline ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: 20vh;
  row-gap: 2em;
}
.timeline ul li .timeline-content {
  width: 250px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}
.timeline ul li .timeline-content h1 {
  font-size: 1rem;
  font-weight: 400;
}
.timeline .timeline-inner {
  transition: ease-in-out 1s;
}
.timeline .timeline-wrapper {
  position: relative;
}
.timeline .timeline-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: white 1px solid;
  border-radius: 50%;
}
.timeline .timeline-bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -17px);
  width: 6000px;
  height: 6px;
  background: repeating-linear-gradient(90deg, #fff, #fff 1px, hsla(0deg, 0%, 100%, 0) 0, hsla(0deg, 0%, 100%, 0) 10px);
}

